
import { defineComponent } from 'vue'
import {
    getPayrollDetailHeader,
    getPayrollDetailList,
    payrollDetailDelete,
    PayrollDto
} from '@/api/payroll'
import { exportPayrollDetail } from '@/api/export'
import batchPayroll from '@/components/batch-payroll.vue'
export default defineComponent({
    name: 'PayrollDetail',
    components: {
        batchPayroll
    },
    data () {
        return {
            isBatchPay: false,
            tableData: [] as PayrollDto[],
            tableHeaderData: [] as { value: string }[],
            pageSize: 10,
            pageNum: 1,
            total: 0,
            searchText: '',
            searchValue: '',
            selects: [] as string[]
        }
    },
    created () {
        // this.getList()
        this.getHeaderList()
        this.getList()
    },
    methods: {
        async getHeaderList () {
            const { id } = this.$route.params
            const result = await getPayrollDetailHeader(id)
            this.tableHeaderData = result
        },
        async getList () {
            const { id } = this.$route.params
            const result = await getPayrollDetailList({
                stubId: id,
                searchText: this.searchText,
                pageNum: this.pageNum,
                pageSize: this.pageSize
            })
            this.total = result.total
            this.tableData = result.list
            console.log(this.tableData, 'tableData')
        },
        handleSizeChange () {
            this.pageNum = 1
            this.getList()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getList()
        },
        onReset () {
            this.searchText = ''
            this.search()
        },
        handleSelectionChange (selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        },
        /**
         * 导出勾选
         */
        async onExport () {
            const result = await exportPayrollDetail({
                ids: this.selects.join(','),
                searchText: this.searchText,
                stubId: this.$route.params.id
            })
            window.location.href =
                process.env.VUE_APP_BASE_URL +
                '/common/download?delete=true&fileName=' +
                result

            this.$message.success('导出成功！')
        },
        /**
         * 删除勾选工资单
         */
        async onDelete () {
            const str = this.selects.join(',')
            if (str.length === 0) {
                this.$message.warning('请至少选择一个！')
                return
            }
            await payrollDetailDelete(str)
            this.$message.success('删除成功！')
            await this.getList()
        }
    }
})
